import { ITaskFilter } from './task-filter.interface';

export class TaskFilter implements ITaskFilter {
  public statuses: string[];

  public person_name: string;

  public users: string[];

  public enterprises: string[];

  public company_id: string;

  constructor(data: any = {}) {
    this.statuses = data.statuses || ['Pendente'];
    this.person_name = data.person_name || '';
    this.users = data.users || [];
    this.enterprises = data.enterprises || [];
    this.company_id = data.company_id || '';
  }

  format() {
    return {
      statuses: this.statuses.length ? this.statuses : undefined,
      person_name: this.person_name ? this.person_name : undefined,
      users: this.users.length ? this.users : undefined,
      enterprises: this.enterprises.length ? this.enterprises : undefined,
      company_id: this.company_id ? this.company_id : undefined,
    };
  }
}
