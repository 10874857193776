
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Container, Draggable } from 'vue-smooth-dnd';
import { ITasks } from '../entities/tasks.entity.interface';
import TaskBoardComponent from './task-board.component.vue';

@Component({
  components: {
    Container,
    Draggable,
    TaskBoardComponent,
  },
})
export default class TaskBoardDialogComponent extends Vue {
  @Prop({
    type: Boolean,
    default: () => false,
  })
  public readonly value!: boolean;

  @Prop({
    type: String,
    default: () => '',
  })
  public readonly companyId!: string;

  @Prop({
    type: String,
    default: () => '',
  })
  public readonly currentTaskId!: string;

  public loading: boolean = false;

  private tasks: ITasks[] = [];

  private columnsTasks: any[] = [];

  public close(data: boolean) {
    if (data === true) return;
    this.$emit('input', false);
  }
}
