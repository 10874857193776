
import { mapGetters } from 'vuex';
import companiesService from '@/modules/Administrative/modules/Companies/services/companies.service';
import { Company } from '@/modules/Administrative/modules/Companies/entities/company.entity';
import enterpriseService from '@/modules/Administrative/modules/Enterprises/services/enterprise.service';
import { Enterprise } from '@/modules/Administrative/modules/Enterprises/entities/enterprise.entity';
import { Component, Vue } from 'vue-property-decorator';
import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import userService from '@/modules/Administrative/modules/Users/services/user.service';
import { ClientFormTasks } from './entities/client-form-tasks.entity';
import tasksService from './services/tasks.service';
import { ITasks } from './entities/tasks.entity.interface';
import { Tasks } from './entities/tasks.entity';
import TaskListItemComponent from './task-list-item.component.vue';
import TaskBoardDialogComponent from './components/task-board-dialog.component.vue';
import { IClientFormTasks } from './entities/client-form-tasks.interface';
import { ITaskFilter } from './entities/task-filter.interface';
import { TaskFilter } from './entities/task-filter';

@Component({
  components: {
    TaskListItemComponent,
    TaskBoardDialogComponent,
  },
  computed: { ...mapGetters(['user']) },
})
export default class TasksComponent extends Vue {
  private companies: Company[] = [];

  private tasks: ITasks[] = [];

  private users: User[] = [];

  private currentTasks: IClientFormTasks[] = [];

  private currentTaskId: string = '';

  public user!: any;

  public filter: ITaskFilter = new TaskFilter();

  private companyId!: string;

  private showTaskBoardDialog: boolean = false;

  private statuses: string[] = [];

  private enterprises: Enterprise[] = [];

  private get hasTasksCreated() {
    return !this.companyId || this.tasks.length;
    // if (!this.companyId || this.tasks.length) {
    //   return false;
    // }

    // return true;
  }

  private goToProposal(task: IClientFormTasks) {
    this.$router.push({
      name: 'client-edit',
      params: {
        id: task.id,
      },
    });
  }

  closeDialog() {
    this.currentTasks = [];
  }

  public async getStatuses() {
    this.statuses = await tasksService.getStatusColumn();
  }

  public setCurrentTasks(tasks: IClientFormTasks[], id: string) {
    this.showTaskBoardDialog = true;
    this.currentTasks = [];
    this.currentTaskId = id;
    this.currentTasks = tasks;
  }

  public async toggleTaskCompleted(content: any) {
    const { data, event } = content;
    data.completed = event;
    if (event) {
      await tasksService.doneTask(data);
      this.getTasks();
      return;
    }
    await tasksService.undoTask(data);
    this.getTasks();
  }

  private async setCompanyId(companyId: string) {
    this.companyId = companyId;
    this.tasks = [];
    this.getTasks();
  }

  private async getCompanies() {
    const companies: Company[] = await companiesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
    if (!this.companyId && this.companies.length) {
      this.companyId = this.companies[0].id;
    }
  }

  private async getEnterprises() {
    const enterprises = await enterpriseService.getAllEnterprises();
    this.enterprises = enterprises.map((enterprise: Enterprise) => new Enterprise(enterprise));
  }

  private async getUsers() {
    const users = await userService.getAllUsers({});
    this.users = users.map((user: User) => new User(user));
  }

  private async getTasks() {
    if (!this.companyId) return;
    const { id } = this.$route.params;
    const tasks: any = await tasksService.getAllTasks(this.companyId, this.filter.format());

    if (tasks.length) {
      this.tasks = [];
      this.tasks = tasks.map(
        (it: any) => new Tasks({
          ...it,
          tasks: it.tasks.map((tasksItem: ClientFormTasks) => new ClientFormTasks(tasksItem)),
        }),
      );
      return;
    }
    this.tasks = [];
  }

  private created() {
    this.companyId = this.user.company_id;
    this.getCompanies();
    this.getTasks();
    this.getStatuses();
    this.getEnterprises();
    this.getUsers();
  }
}
