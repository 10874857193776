import { render, staticRenderFns } from "./task-board-dialog.component.vue?vue&type=template&id=cfa318d0&scoped=true&"
import script from "./task-board-dialog.component.vue?vue&type=script&lang=ts&"
export * from "./task-board-dialog.component.vue?vue&type=script&lang=ts&"
import style0 from "./task-board-dialog.component.vue?vue&type=style&index=0&id=cfa318d0&prod&lang=sass&scoped=true&"
import style1 from "./task-board-dialog.component.vue?vue&type=style&index=1&id=cfa318d0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfa318d0",
  null
  
)

export default component.exports